import { Row } from "@tanstack/react-table";
import { IActivityListDto, IIdeaListDto, ImprovementStatus } from "../../../../api/improvements";
import { isPast, isToday, isBefore, addYears, addDays } from "date-fns";
import { IProjectActivityDto, IProjectDto } from "../../../../api/projects";
import { IssueStatus, ModuleStatus } from "../../../../api/types/Enum";
import { IAccidentDto, IActivityDto, IIssueDto } from "../../../../api/issues";
import { ISupplierDto } from "../../../../api/suppliers";

export const improvementIdeaStatusRowClass = (row: Row<IIdeaListDto>): string => {
    if (row.original.status === 0 || row.original.status === 4) return 'tr-positive';
    return ""
}
export const improvementActivitiesStatusRowClass = (row: Row<IActivityListDto>): string => {
    const { status, plannedFinishedAt } = row.original;

    if (status === ImprovementStatus.Closed) {
        return 'tr-positive';
    }

    if (status === ImprovementStatus.Evaluate) {
        return 'tr-info';
    }

    if (status === ImprovementStatus.PendingDecision) {
        return 'tr-warning';
    }

    if (
        (status === ImprovementStatus.Active || status === ImprovementStatus.DecisionMade) &&
        plannedFinishedAt && (isToday(new Date(plannedFinishedAt)) ||
            isPast(new Date(plannedFinishedAt)))
    ) {
        return 'tr-negative';
    }

    return '';
};
export const issueStatusRowClass = (row: Row<IIssueDto | IAccidentDto>): string => {
    const { status, doneBefore } = row.original;

    if (status === IssueStatus.Closed) return 'tr-positive'
    else if (status === IssueStatus.Evaluate) return 'tr-info';
    else if (doneBefore && (isToday(new Date(doneBefore)) ||
        isPast(new Date(doneBefore)))) return 'tr-negative';
    return '';
};
export const issueActivityStatusRowClass = (row: Row<IActivityDto>): string => {
    const { status, doneBefore } = row.original;
    if (status === IssueStatus.Closed) return 'tr-positive'
    else if (doneBefore && (isToday(new Date(doneBefore)) ||
        isPast(new Date(doneBefore)))) return 'tr-negative';
    return '';
};

export const projectStatusRowClass = (row: Row<IProjectDto>): string => {
    const { status, endDate } = row.original;

    if (status === ModuleStatus.Closed) return 'tr-positive'
    else if (status === ModuleStatus.Evaluate) return 'tr-info';
    else if (endDate && (isToday(new Date(endDate)) ||
        isPast(new Date(endDate)))) return 'tr-negative';
    return '';
};

export const projectActivityStatusRowClass = (row: Row<IProjectActivityDto>): string => {
    const { status, doneBefore } = row.original;

    if (status === ModuleStatus.Closed) return 'tr-positive'
    else if (status === ModuleStatus.Evaluate) return 'tr-info';
    else if (doneBefore && (isToday(new Date(doneBefore)) ||
        isPast(new Date(doneBefore)))) return 'tr-negative';
    return '';
};

