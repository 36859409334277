import React, { useMemo } from 'react'
import { Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import * as API from '../../../../api'
import Loading from '../../../common/Loading'
import { createColumnHelper, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import FullHeightStackingContainer from '../../../common/fullHeightStackingContainer'
import TableReact from '../../../wrappers/TableReact'
import { getState } from '../../../../state'
import { ISupplierDeviationDto, ISupplierResponse } from '../../../../api/suppliers'
import DateCell from '../../../common/cells/DateCell'
import { formatDate, isInPageRenderMode } from '../../../../actions/util'
import { DateRangeFilter, dateBetweenFilterFn } from '../../../wrappers/TableReact/Filters/DateRangeFilter'
import { DepartmentDisplay } from '../../../common/globalStateDisplay'
import { getDepartmentFromState, getUserFromState } from '../../../../state/helpers'
import { MultiDropdownFilter, multiFilter } from '../../../wrappers/TableReact/Filters/MultiDropdownFilter'
import UserNameDisplay from '../../../common/UserNameDisplay'
import { IssueStatus } from '../../../../api/types/Enum'
import { EnumMultiFilterSelect, enumMultiFilter } from '../../../wrappers/TableReact/Filters/EnumMultiFilter'
import { useUserOptionsOverride } from '../../../../hooks/users'
import { Link } from 'react-router-dom'

interface ISupplierDeviationListProps {
    selectedSupplier: ISupplierResponse
}
const SupplierDeviationList = ({ selectedSupplier }: ISupplierDeviationListProps) => {
    const { t } = useTranslation()
    const { state } = getState()
    const {
        isLoading,
        data,
    } = useGetQueryByParams(true, API.suppliers.getDeviations, "supplierDeviationList", selectedSupplier.id)
    const userOptions = useUserOptionsOverride(t)
    const columnHelper = createColumnHelper<ISupplierDeviationDto>()
    const columns = useMemo(
        () => [
            columnHelper.accessor((r) => r.ownId.toString(), {
                id: "ownId",
                header: t('ownId') as any,
                size: 60,
            }),
            columnHelper.accessor((r) => t(`issueStatus.${r.status}`), {
                id: "status",
                header: t('status') as any,
                filterFn: enumMultiFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: string | IssueStatus[]) => void, value: IssueStatus[]) => (
                        <EnumMultiFilterSelect
                            setFilterValue={setFilterValue}
                            value={value}
                            Enum={IssueStatus}
                            enumLangKey="issueStatus"
                        />
                    ),
                },
                size: 80,
                cell: ({ row }) => <span>{t(`issueStatus.${row.original.status}`)}</span>
            }),
            columnHelper.accessor("topic", {
                id: "topic",
                header: t('topic') as any,
                cell: ({ row }) => (
                    <a href={isInPageRenderMode() ? `/issues/detail/${row.original.id}` : `/avvikelse/avv.aspx#/detail/${row.original.id}`}>
                        {row.original.topic}
                    </a>
                )
            }),
            columnHelper.accessor((r) => getUserFromState(state)(r.responsibleId)?.name ?? "", {
                id: "responsibleId",
                header: t('responsibleId') as any,
                filterFn: multiFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: string) => void, value: string[]) => (
                        <MultiDropdownFilter
                            setFilterValue={setFilterValue}
                            value={value}
                            options={userOptions}

                        />
                    ),
                },
                cell: ({ row }) => <UserNameDisplay
                    userId={row.original.responsibleId ?? undefined}
                    fallbackValue={t('userFromAnotherCompany')}
                />
            }),
            columnHelper.accessor((r) => r.departmentId ? getDepartmentFromState(state)(r.departmentId)?.name : "", {
                id: "departmentId",
                header: t('department') as any,
                filterFn: multiFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: string) => void, value: string[]) => (
                        <MultiDropdownFilter
                            setFilterValue={setFilterValue}
                            value={value}
                            options={state.departments.map(d => ({ value: d.id, text: d.name })) ?? []}
                        />
                    ),
                },
                cell: ({ row }) => <DepartmentDisplay departmentId={row.original.departmentId} />
            }),
            columnHelper.accessor(r => r.doneBefore ? formatDate(r.doneBefore) : "", {
                id: "doneBefore",
                header: t('doneBefore') as any,
                filterFn: dateBetweenFilterFn,
                meta: {
                    filterComponent: (setFilterValue: (updater: any) => void, value: Date[]) => (
                        <DateRangeFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                },
                cell: ({ row }) => <DateCell date={row.original.doneBefore} />
            }),
            columnHelper.accessor((r) => r.time ? r.time.toString() : "", {
                id: "time",
                header: t('time') as any,
                size: 80,
            }),
            columnHelper.accessor((r) => r.cost ? r.cost.toString() : "", {
                id: "cost",
                header: t('cost') as any,
                size: 80,
            }),
            columnHelper.accessor(r => r.reportedAt ? formatDate(r.reportedAt) : "", {
                id: "reportDate",
                header: t('reportDate') as any,
                filterFn: dateBetweenFilterFn,
                meta: {
                    filterComponent: (setFilterValue: (updater: any) => void, value: Date[]) => (
                        <DateRangeFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                },
                cell: ({ row }) => <DateCell date={row.original.reportedAt} />
            }),
        ], [columnHelper, t, state, userOptions])

    const table = useReactTable({
        data: data || [],
        columnResizeMode: "onChange",
        columns: columns,
        enableGlobalFilter: false,
        enableGrouping: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <FullHeightStackingContainer>
            <Message info>
                {t("supplierDeviationsInfo")}
            </Message>

            <TableReact table={table}
                canExport={false}
                overflowable />
        </FullHeightStackingContainer>
    )
}

export default SupplierDeviationList
