import React, { useMemo, useState } from 'react'
import * as API from '../../../../../api'
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams'
import { getState } from '../../../../../state'
import Loading from '../../../../common/Loading'
import { useTranslation } from 'react-i18next'
import { buildAccidentTitleKey, transformToStatisticsYearMonthRow } from '../../../../../utils/Mappings/Issues'
import { IDropdownOption } from '../../../../fields/ControlDropdown'
import { Dropdown, Grid, GridColumn, GridRow, Header, Message } from 'semantic-ui-react'
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { IAccidentStatisticsResponse } from '../../../../../api/issues'
import {
    createColumnHelper, getCoreRowModel, getExpandedRowModel, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel, getGroupedRowModel, getPaginationRowModel, getSortedRowModel, useReactTable
} from '@tanstack/react-table'
import TableReact, { FooterLabel } from '../../../../wrappers/TableReact'
import EmploymentTypeStatistic from './EmploymentTypeStatistic'
import AbsenceTimeTypeStatistic from './AbsenceTimeTypeStatistic'
import AccidentCauseStatistic from './AccidentCauseStatistic'
import { LocationDisplay } from '../../../../common/globalStateDisplay'
import { MultiDropdownFilter, multiFilter } from '../../../../wrappers/TableReact/Filters/MultiDropdownFilter'
import { getLocationFromState } from '../../../../../state/helpers'
import { handleAccidentGraphColors } from '../../../../../styles'
import CustomBarLabel from '../CustomBarLabel'

const AccidentStatistics = () => {
    const {
        state: { locations, departments }, state,
    } = getState()
    const { t } = useTranslation()
    const [selectedYears, setSelectedYears] = useState<number[]>([new Date().getFullYear()]);
    const [selectedMonths, setSelectedMonths] = useState<number[]>([]);
    const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
    const [selectedLocations, setSelectedLocations] = useState<number[]>([]);
    const [selectedTypes, setSelectedTypes] = useState<number[]>([]);
    const [selectedStats, setSelectedStats] = useState<number[]>([2]);
    const hasMonth = selectedMonths.length
    const hasDepartment = selectedDepartments.length
    const hasLocations = state.locations?.length && selectedLocations.length
    const {
        isLoading,
        data,
    } = useGetQueryByParams(true, API.issues.postAccidentsFilteredStats, "accidentStats", { selectedYears, selectedMonths, selectedDepartments, selectedLocations, selectedTypes })

    const columnHelper = createColumnHelper<IAccidentStatisticsResponse>()

    const deviationTypeColumns = useMemo(
        () => [
            columnHelper.accessor('year', {
                id: 'year',
                header: t('year') as any,
            }),
            ...(
                hasMonth
                    ? [columnHelper.accessor('month', {
                        id: 'month',
                        header: t('month') as any,

                    }),
                    ]
                    : []
            ),
            columnHelper.accessor('type', {
                id: 'type',
                header: t('type') as any,
                cell: (info) => <span>{t(`accidentReason.${info.getValue()}`)}</span>
            }),
            ...(
                hasDepartment
                    ? [columnHelper.accessor('department', {
                        id: 'department',
                        header: t('departmentId') as any,
                    }),
                    ]
                    : []
            ),
            ...(
                hasLocations
                    ? [columnHelper.accessor((r) => r.locationId ? getLocationFromState(state)(r.locationId)?.name : "", {
                        id: "locationId",
                        header: `${t('locationId')}`,
                        filterFn: multiFilter,
                        meta: {
                            filterComponent: (setFilterValue: (updater: string) => void, value: string[]) => (
                                <MultiDropdownFilter
                                    setFilterValue={setFilterValue}
                                    value={value}
                                    options={state.locations?.map(d => ({ value: d.id, text: d.name })) ?? []}
                                />
                            ),
                        },
                        cell: ({ row }) => <LocationDisplay locationId={row.original.locationId} />
                    }),
                    ]
                    : []
            ),
            columnHelper.accessor('count', {
                id: 'count',
                header: t('count') as any,
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows
                    if (filtered.length === 0) {
                        return null
                    }
                    const totalCount = filtered
                        .map((f) => f.original.count)
                        .reduce((a: number, c: number) => a + c, 0)
                    return (
                        <FooterLabel>{t('totalValue', { value: totalCount })}</FooterLabel>
                    )
                },
            }),
        ], [columnHelper, t, state.locations, hasMonth, hasDepartment, hasLocations])

    const filteredData = useMemo(() => {
        return data ? data.filter(x => x.type !== null) : [];
    }, [data]);

    const table = useReactTable({
        data: filteredData || [],
        columnResizeMode: "onChange",
        columns: deviationTypeColumns,
        enableGlobalFilter: false,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    if (isLoading || !data) {
        return <Loading />
    }

    const mappedData = {
        barData: transformToStatisticsYearMonthRow(
            data ? data : [],
            x => buildAccidentTitleKey(x, selectedYears, selectedMonths, selectedDepartments, selectedLocations, state, t),
            x => x.type ? ({ [t(`accidentReason.${x.type}`)]: x.count }) : "",
        ),

    };

    const handleDropdownChange = (
        value: number[],
        selectedState: number[],
        setState: React.Dispatch<React.SetStateAction<number[]>>,
        options: IDropdownOption[],
    ) => {
        if (value.includes(-1)) {
            if (selectedState.length === options.length - 1) {
                setState([]);
            } else {
                setState(options.slice(1).map(option => option.value as number));
            }
        } else {
            setState(value);
        }
    };
    const currentYear = new Date().getFullYear();

    const selectAllOption: IDropdownOption = { value: -1, text: t('selectAll') }
    const years = [selectAllOption];
    const months = [selectAllOption];

    for (let year = 2011; year <= currentYear; year++) {
        years.push({ value: year, text: String(year) });
    }

    for (let i = 1; i < 12; i++) {
        months.push({ value: i, text: t(`month.${i}`) });
    }

    const typeOptions = [
        selectAllOption,
        { value: 0, text: t('accidentReason.0') },
        { value: 1, text: t('accidentReason.1') },
        { value: 2, text: t('accidentReason.2') }
    ]
    const departmentOptions = [selectAllOption, ...departments?.map(d => ({ value: d.id, text: d.name }))]
    const locationOptions = locations && locations.length ? [selectAllOption, ...locations?.map(d => ({ value: d.id, text: d.name }))] : []
    const statsOptions = [selectAllOption, ...[{ value: 1, text: t("table") }, { value: 2, text: t("barChart") },
    { value: 3, text: t("accidentEmploymentType") }, { value: 4, text: t("accidentAbsence") }, { value: 5, text: t("accidentCause") }]]

    return (
        <Grid stackable>
            <GridRow>
                <Grid.Column>
                    <Message info>
                        <Message.Header>
                            {t('customizeStats')}
                        </Message.Header>
                        {t('customizeStatsInfo')}
                    </Message>
                </Grid.Column>
            </GridRow>
            <GridRow columns={4}>
                <GridColumn>
                    <label>{t('statisticType')}</label>
                    <Dropdown
                        options={statsOptions}
                        value={selectedStats}
                        onChange={(_, x) => handleDropdownChange(x.value as number[], selectedStats, setSelectedStats, statsOptions)}
                        fluid
                        multiple
                        search
                        selection
                        clearable
                    />
                </GridColumn>
            </GridRow>
            <GridRow columns={2}>
                <GridColumn>
                    <label>{t('captionYear')}</label>
                    <Dropdown
                        options={years}
                        value={selectedYears}
                        onChange={(_, x) => handleDropdownChange(x.value as number[], selectedYears, setSelectedYears, years)}
                        fluid
                        multiple
                        search
                        selection
                        clearable
                    />
                </GridColumn>
                <GridColumn>
                    <label>{t('month')}</label>
                    <Dropdown
                        options={months}
                        value={selectedMonths}
                        onChange={(_, x) => handleDropdownChange(x.value as number[], selectedMonths, setSelectedMonths, months)}
                        fluid
                        multiple
                        search
                        selection
                        clearable
                    />
                </GridColumn>
            </GridRow>
            <GridRow columns={3}>
                <GridColumn>
                    <label>{t('type')}</label>
                    <Dropdown
                        options={typeOptions}
                        value={selectedTypes}
                        onChange={(_, x) => handleDropdownChange(x.value as number[], selectedTypes, setSelectedTypes, typeOptions)}
                        fluid
                        multiple
                        search
                        selection
                        clearable
                    />
                </GridColumn>
                <GridColumn>
                    <label>{t('departmentId')}</label>
                    <Dropdown
                        options={departmentOptions}
                        value={selectedDepartments}
                        onChange={(_, x) => handleDropdownChange(x.value as number[], selectedDepartments, setSelectedDepartments, departmentOptions)}
                        fluid
                        multiple
                        search
                        selection
                        clearable
                    />
                </GridColumn>

                {locations && locations.length ? <GridColumn>
                    <label>{t('locationId')}</label>
                    <Dropdown
                        options={locationOptions}
                        value={selectedLocations}
                        onChange={(_, x) => handleDropdownChange(x.value as number[], selectedLocations, setSelectedLocations, locationOptions)}
                        fluid
                        multiple
                        search
                        selection
                        clearable
                    />
                </GridColumn> : null}

            </GridRow>


            {selectedStats.includes(1) && <GridRow columns={1}>
                <Grid.Column>
                    <Header as='h3' textAlign='center'>{t('table')}</Header>
                </Grid.Column>
                <Grid.Column >
                    <TableReact table={table}
                        canExport
                        overflowable />
                </Grid.Column></GridRow>}

            {selectedStats.includes(2) && (mappedData?.barData?.rows?.length ?? 0) > 0 ? (
                <GridRow columns={1}>
                    <Grid.Column>
                        <Header as='h3' textAlign='center'>{t('barChart')}</Header>
                    </Grid.Column>
                    <Grid.Column>
                        <ResponsiveContainer height={500}
                        >
                            <BarChart data={mappedData?.barData?.rows}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <CartesianGrid vertical={false} />
                                <Legend layout="horizontal" />
                                {mappedData?.barData?.dataColumns?.map(x => (
                                    <Bar key={x} stackId="0" dataKey={x} fill={handleAccidentGraphColors(x, t)}>
                                        <LabelList dataKey={x} content={CustomBarLabel} />
                                    </Bar>
                                ))}
                            </BarChart>
                        </ResponsiveContainer>
                    </Grid.Column>
                </GridRow>
            ) : null}

            {selectedStats.includes(3) ? <EmploymentTypeStatistic
                selectedYears={selectedYears}
                selectedMonths={selectedMonths}
                selectedDepartments={selectedDepartments}
                selectedLocations={selectedLocations} /> : null}

            {selectedStats.includes(4) ? <AbsenceTimeTypeStatistic
                selectedYears={selectedYears}
                selectedMonths={selectedMonths}
                selectedDepartments={selectedDepartments}
                selectedLocations={selectedLocations} /> : null}

            {selectedStats.includes(5) ? <AccidentCauseStatistic
                selectedYears={selectedYears}
                selectedMonths={selectedMonths}
                selectedDepartments={selectedDepartments}
                selectedLocations={selectedLocations} /> : null}
        </Grid>
    )
}

export default AccidentStatistics