import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Grid, GridColumn, GridRow, Message } from 'semantic-ui-react';
import ControlCheckbox from '../../../fields/ControlCheckbox';
import * as z from 'zod';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getState } from '../../../../state';
import ControlDateField from '../../../fields/ControlDateField';
import ControlTextArea from '../../../fields/ControlTextArea';
import ControlInput from '../../../fields/ControlInput';
import ControlRadioGroup from '../../../fields/ControlRadioGroup';
import * as API from '../../../../api'
import { handleAxiosError } from '../../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ISupplierResponse } from '../../../../api/suppliers';
import FormActions from '../../FormActions';
import { mapSupplierToRequest } from '../../../../utils/Mappings/Suppliers';
import { mockedSupplier } from '../../../../utils/MockedData/Suppliers';

interface ISupplierContactInformationFormProps {
    isNew: boolean
    refresh?: () => void
    selectedSupplier?: ISupplierResponse
}

export const supplierSchema = z.object({
    name: z.string().min(1, "required"),
    group: z.string().min(1, "required"),
    active: z.boolean().nullable(),
    language: z.string().min(1, "required"),
    service: z.string().optional().nullable(),
    quality: z.boolean().nullable(),
    environment: z.boolean().nullable(),
    workEnvironment: z.boolean().nullable(),
    csr: z.boolean().nullable(),
    loadBearingStructureWeldingCertificate: z.boolean().nullable(),
    qualityCertificationExpires: z.date().nullable(),
    environmentCertificationExpires: z.date().nullable(),
    workEnvironmentCertificationExpires: z.date().nullable(),
    csrCertificationExpires: z.date().nullable(),
    otherCertificationExpires: z.date().nullable(),
    email: z.string().email('invalidEmail'),
    interval: z.number().nullable(),
    contact: z.string().min(1, "required"),
    position: z.string().optional().nullable(),
    zipCode: z.string().optional().nullable(),
    organizationNumber: z.string().min(1, "required"),
    location: z.string().optional().nullable(),
    telephoneNumber: z.string().optional().nullable(),
    country: z.string().optional().nullable(),
    webpage: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
    comment: z.string().optional().nullable(),
    address: z.string().optional().nullable(),
}).refine((data) => {
    return data.quality || data.environment || data.workEnvironment || data.csr || data.loadBearingStructureWeldingCertificate;
}, {
    message: "supplierAffectsError",
    path: ["quality"],
});

const SupplierContactInformationForm = ({ refresh, isNew, selectedSupplier }: ISupplierContactInformationFormProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit } = useForm<ISupplierResponse>({
        resolver: zodResolver(supplierSchema),
        defaultValues: selectedSupplier ? mapSupplierToRequest(selectedSupplier) : mockedSupplier()
    });

    const onSupplierRequest = async (data: ISupplierResponse) => {
        if (isNew) {
            return await API.suppliers.create(data)
        } else {
            return await API.suppliers.edit(selectedSupplier!.id, data)
        }
    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onSupplierRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async (data) => {
            if (data) {
                toast.success(t("saved"))
                if (refresh) refresh()
                navigate(-1)
            }

        },
    })
    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow columns="2">
                    <GridColumn verticalAlign='middle' >
                        <ControlCheckbox name="active" label={t('activeSupplier')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <FormActions
                            onCancel={() => navigate(-1)}
                            disabled={isSaving}
                        />
                    </GridColumn>
                </GridRow>
                <GridRow columns="1" >
                    <GridColumn >
                        <h3>{t('affects')}</h3>
                    </GridColumn>
                    <GridColumn >
                        <Message info style={{ marginTop: "1em" }}>
                            {t("supplierAffectsInfo")}
                        </Message>
                    </GridColumn>
                </GridRow>

                <GridRow columns="5">
                    <GridColumn>
                        <ControlCheckbox name="quality" label={t('quality')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn>
                        <ControlCheckbox name="environment" label={t('environment')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn>
                        <ControlCheckbox name="workEnvironment" label={t('workEnvironment')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn>
                        <ControlCheckbox name="csr" label={t('csr')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn>
                        <ControlCheckbox name="loadBearingStructureWeldingCertificate" label={t('loadBearingStructureWeldingCertificate')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                {selectedSupplier?.sId && <GridRow columns="1">
                    <GridColumn>
                        <label>{t('linkSupplierQuestions')}</label>
                    </GridColumn>
                    <GridColumn>
                        <a target="_blank" rel="noopener noreferrer" href={`https://viqmasystem.se/lev/form.aspx?id=${selectedSupplier.sId}`}>{`https://viqmasystem.se/lev/form.aspx?id=${selectedSupplier.sId}`}</a>
                    </GridColumn>
                </GridRow>}

                <GridRow columns="2">
                    <GridColumn >
                        <ControlRadioGroup control={control} name='group' disabled={isSaving} label={t('group')}
                            options={[
                                { value: "A", text: "A" },
                                { value: "B", text: "B" },
                                { value: "C", text: "C" },
                            ]} />
                    </GridColumn>
                    <GridColumn >
                        <ControlRadioGroup control={control} name='language' disabled={isSaving} label={t('language')}
                            options={[
                                { value: "swe", text: t("swe") },
                                { value: "eng", text: t("eng") },
                            ]} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    <GridColumn>
                        <ControlInput name="name" label={t('name')} required control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlInput name="service" label={t('service')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    <GridColumn>
                        <ControlInput name="organizationNumber" label={t('organizationNumber')} required control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlInput name="contact" label={t('contact')} required control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    <GridColumn>
                        <ControlInput name="address" label={t('address')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlInput name="position" label={t('position')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    <GridColumn>
                        <ControlInput name="zipCode" label={t('zipCode')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlInput name="email" label={t('email')} required control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    <GridColumn>
                        <ControlInput name="location" label={t('location')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlInput name="telephoneNumber" label={t('telephoneNumber')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    <GridColumn>
                        <ControlInput name="Country" label={t('Country')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlInput name="webPage" label={t('webPage')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <h3>{t('expireCertDate')}</h3>
                <GridRow columns="5">
                    <GridColumn>
                        <ControlDateField name="qualityCertificationExpires" label={t('qualityCertificationExpires')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlDateField name="environmentCertificationExpires" label={t('environmentCertificationExpires')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn>
                        <ControlDateField name="workEnvironmentCertificationExpires" label={t('workEnvironmentCertificationExpires')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlDateField name="csrCertificationExpires" label={t('csrCertificationExpires')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlDateField name="otherCertificationExpires" label={t('otherCertificationExpires')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn>
                        <ControlTextArea name="description" label={t('descriptionSupplier')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                    <GridColumn>
                        <ControlTextArea name="comment" label={t('commentSupplier')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                    <GridColumn>
                        <FormActions
                            onCancel={() => navigate(-1)}
                            disabled={isSaving}
                        />
                    </GridColumn>
                </GridRow>
            </Grid>
        </Form>
    );
};

export default SupplierContactInformationForm;
